import { LatLngExpression } from 'leaflet';
import {
  CatastrophePhase,
  CatastropheSeverity,
  CatType,
} from '~/src/generatedX/graphql';

export const SHOW_REAL_CAT = 'show_real_cat';

export type CatTypeValue = (typeof CatType)[keyof typeof CatType];

export const catTypeLabels: { [key in CatTypeValue]: string } = {
  [CatType.Blizzard]: 'Blizzard',
  [CatType.DustStorm]: 'Dust Storm',
  [CatType.Earthquake]: 'Earthquake',
  [CatType.Flood]: 'Flood',
  [CatType.Hailstorm]: 'Hailstorm',
  [CatType.Hurricane]: 'Hurricane',
  [CatType.IceStorm]: 'Ice Storm',
  [CatType.LightningStorm]: 'Thunderstorm',
  [CatType.Tornado]: 'Tornado',
  [CatType.Wildfire]: 'Wildfire',
  [CatType.Windstorm]: 'Windstorm',
  [CatType.WinterStorm]: 'Winter Storm',
};

export const catPhaseLabels: { [key in CatastrophePhase]: string } = {
  [CatastrophePhase.Before]: 'Before',
  [CatastrophePhase.During]: 'During',
  [CatastrophePhase.After]: 'After',
};

export const catSeverityLabels: { [key in CatastropheSeverity]: string } = {
  [CatastropheSeverity.Low]: 'Low',
  [CatastropheSeverity.Medium]: 'Medium',
  [CatastropheSeverity.High]: 'High',
};

export const ORDERED_EVENT_TYPE_OPTIONS: {
  label: string;
  value: CatTypeValue;
}[] = [
  {
    label: catTypeLabels.WINTER_STORM,
    value: CatType.WinterStorm,
  },
  { label: catTypeLabels.BLIZZARD, value: CatType.Blizzard },
  {
    label: catTypeLabels.ICE_STORM,
    value: CatType.IceStorm,
  },
  {
    label: catTypeLabels.HURRICANE,
    value: CatType.Hurricane,
  },
  {
    label: catTypeLabels.FLOOD,
    value: CatType.Flood,
  },
  {
    label: catTypeLabels.TORNADO,
    value: CatType.Tornado,
  },
  {
    label: catTypeLabels.HAILSTORM,
    value: CatType.Hailstorm,
  },
  {
    label: catTypeLabels.LIGHTNING_STORM,
    value: CatType.LightningStorm,
  },
  {
    label: catTypeLabels.WILDFIRE,
    value: CatType.Wildfire,
  },
  {
    label: catTypeLabels.WINDSTORM,
    value: CatType.Windstorm,
  },
  {
    label: catTypeLabels.DUST_STORM,
    value: CatType.DustStorm,
  },
  {
    label: catTypeLabels.EARTHQUAKE,
    value: CatType.Earthquake,
  },
];

export const ORDERED_SEVERITY_OPTIONS: {
  label: string;
  value: CatastropheSeverity;
}[] = [
  {
    label: catSeverityLabels.LOW,
    value: CatastropheSeverity.Low,
  },
  {
    label: catSeverityLabels.MEDIUM,
    value: CatastropheSeverity.Medium,
  },
  {
    label: catSeverityLabels.HIGH,
    value: CatastropheSeverity.High,
  },
];

export const ORDERED_PHASE_OPTIONS: {
  label: string;
  value: CatastrophePhase;
}[] = [
  {
    label: catPhaseLabels.BEFORE,
    value: CatastrophePhase.Before,
  },
  {
    label: catPhaseLabels.DURING,
    value: CatastrophePhase.During,
  },
  {
    label: catPhaseLabels.AFTER,
    value: CatastrophePhase.After,
  },
];

export const ORDERED_CAT_PHASES = [
  CatastrophePhase.Before,
  CatastrophePhase.During,
  CatastrophePhase.After,
];

export const DEFAULT_MAP_CENTER: LatLngExpression = [
  39.8097343,
  -98.5556199 - 5,
];
export const DEFAULT_MAP_ZOOM = 4;
export const DEFAULT_MAP_BOUNDS = {
  southWest: {
    lat: 8.320212289522944,
    lng: -126.12304687500001,
  },
  northEast: {
    lat: 61.56457388515458,
    lng: -81.03515625000001,
  },
};
