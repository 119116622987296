import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLookupClaimViewUrlQuery } from '~/src/generatedX/graphql';

import PageLoader from '@assured/frontend/src/components/PageLoader';
import { useQueryStringParsed } from '@assured/frontend/src/hooks';

import Layout from '../Layout/Layout';
import { Placeholder } from '../shared/Placeholder';

export const OpenClaimRedirector = () => {
  const history = useHistory();
  const location = useLocation();
  const qs = useQueryStringParsed(location);

  const confirmationNumber =
    qs.get('ClaimNumber') ?? qs.get('CenturyClaimNumber') ?? '';
  const contactExternalId = qs.get('VendorPartyId') ?? undefined;

  const { data, loading, error } = useLookupClaimViewUrlQuery({
    variables: {
      confirmationNumber,
      contactExternalId,
    },
  });

  useEffect(() => {
    if (data?.lookupClaimViewUrl) {
      const url = new URL(data.lookupClaimViewUrl);
      history.replace(`${url.pathname}${url.search}`);
    }
  }, [history, data?.lookupClaimViewUrl]);

  if (!confirmationNumber) {
    return (
      <Placeholder message="No claim number provided. Please check the URL and try again." />
    );
  }

  if (!loading && (error || !data?.lookupClaimViewUrl)) {
    return (
      <Placeholder message="Unable to find claim. Please check the URL and try again." />
    );
  }

  return (
    <Layout navigation={[]}>
      <PageLoader style={{ minHeight: '80rvh' }} text="Loading claim..." />
    </Layout>
  );
};
