import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { SELECTED_MESSAGE_QUERY_PARAM_KEY } from './constants';

export const useSelectedMessageCursor = () => {
  const { search } = useLocation();

  const params = useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);

  return params.get(SELECTED_MESSAGE_QUERY_PARAM_KEY);
};
