import classNames from 'classnames';
import { Fragment, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useFeatureFlag } from '~/src/hooks/useFeatureFlag';

import { Menu, Transition } from '@headlessui/react';

import config from '../../config';
import { useAuth } from '../../hooks/useAuth';
import { Settings } from '../../hooks/useLocalSettings';
import { AdjusterUserAvatar } from '../CaseView2/messaging/AdjusterUserAvatar';
import { OOO_SETTINGS_MODAL_QUERY_PARAM_KEY } from '../CaseView2/messaging/OutOfOfficeSettings/constants';
import { useOutOfOfficeSettingsModalContext } from '../CaseView2/messaging/OutOfOfficeSettings/OutOfOfficeSettingsContext';

const FALLBACK_PROFILE_PHOTO =
  'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80';

type ProfileMenuProps = {
  settings: Settings;
  updateSettings: (newSettings: Partial<Settings>) => void;
};

export const ProfileMenu = ({ settings, updateSettings }: ProfileMenuProps) => {
  const { user, logout } = useAuth();
  const { search } = useLocation();
  const showOOOSettings = useFeatureFlag<boolean>('eng-5733-inbox', false);
  const { outOfOfficeActive } = useOutOfOfficeSettingsModalContext();

  const outOfOfficeSearchTo = useMemo(() => {
    const params = new URLSearchParams(search);
    params.set(OOO_SETTINGS_MODAL_QUERY_PARAM_KEY, '');

    return params.toString();
  }, [search]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button className="bg-white rounded-full flex text-sm">
            <span className="sr-only">Open user menu</span>
            {showOOOSettings ? (
              <AdjusterUserAvatar
                name={user.name || ''}
                outOfOffice={outOfOfficeActive}
              />
            ) : (
              <img
                className="h-8 w-8 rounded-full"
                src={
                  config.authentication ? user?.picture : FALLBACK_PROFILE_PHOTO
                }
                alt=""
              />
            )}
          </Menu.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute z-[60] right-0 mt-2 w-72 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none shadow overflow-hidden rounded"
            >
              <div className="py-1">
                <div className="py-2 px-4">
                  <div className="font-bold text-sm text-gray-800">
                    {user?.name}
                  </div>
                  <div className="text-sm text-gray-600 break-words">
                    {user?.email}
                  </div>
                  <div className="text-xs text-gray-400 mt-1">{user?.sub}</div>
                </div>
                {user?.god ? (
                  <Menu.Item>
                    {({ active }) => (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                      <a
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          updateSettings({
                            godMode: !settings.godMode,
                          });
                          setTimeout(() => {
                            window.location.reload();
                          }, 0);
                        }}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700 font-base cursor-pointer',
                        )}
                      >
                        {settings.godMode
                          ? 'Disable Assured advanced mode'
                          : 'Enable Assured advanced mode'}
                      </a>
                    )}
                  </Menu.Item>
                ) : null}
                {showOOOSettings ? (
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={{
                          search: outOfOfficeSearchTo,
                        }}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700 font-base cursor-pointer',
                        )}
                      >
                        OOO Settings
                      </Link>
                    )}
                  </Menu.Item>
                ) : null}
                <Menu.Item>
                  {({ active }) => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                    <a
                      onClick={() => logout()}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700 font-base cursor-pointer',
                      )}
                    >
                      Log out
                    </a>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
