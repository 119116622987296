import { initialize, LDClient } from 'launchdarkly-js-client-sdk';
import React from 'react';

import { buildLaunchDarklyClientSideProviderConfig } from '@assured/utilities/src/launchDarklySetup';

interface AssuredLDContextValue {
  ldClient: LDClient;
}

const AssuredLDContext = React.createContext<AssuredLDContextValue | null>(
  null,
);

export const useLDClient = () => {
  const context = React.useContext(AssuredLDContext);
  return context?.ldClient;
};

interface Props {
  children: React.ReactNode;
  client: LDClient;
}

export const LDProvider = ({ client, children }: Props) => {
  const value: AssuredLDContextValue = React.useMemo(() => {
    return {
      ldClient: client,
    };
  }, [client]);

  return (
    <AssuredLDContext.Provider value={value}>
      {children}
    </AssuredLDContext.Provider>
  );
};

export const setup = (
  config: ReturnType<typeof buildLaunchDarklyClientSideProviderConfig>,
) => {
  const { clientSideID, context, options } = config;
  const ldClient = initialize(clientSideID, context, options);

  return ldClient;
};
